import { ACCESS_TOKEN, SHARED_SESSION, ROUTES } from 'constants/index';
import * as cookie from 'helpers/cookies';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import CookieService from 'services/Cookie/CookieService';

import ServerSideService from 'services/ServerSide/ServerSideService';

const UnAuthorizedLayout = ({ children }) => {
  const router = useRouter();
  const accessToken = ServerSideService.getAccessToken() ?? cookie.getCookies(ACCESS_TOKEN);
  const loginSession = ServerSideService.getLoginSession() ?? cookie.getCookies(SHARED_SESSION);
  // check if users are logged in
  const isAllowedToDisplay = !accessToken && !loginSession;
  useEffect(() => {
    if (!isAllowedToDisplay) {
      // invalid login session
      CookieService.clearCookies();
      router.push(ROUTES.LOGIN);
    }
  }, []);
  return (
    <div className="un-authorized-layout">
      {isAllowedToDisplay && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

UnAuthorizedLayout.propTypes = {
  children: PropTypes.element,
};

UnAuthorizedLayout.defaultProps = {
  children: null,
};

export default UnAuthorizedLayout;
