import { Form } from 'antd';
import PropTypes from 'prop-types';
import { ALERT_TYPE } from 'constants/index';

const AlertBox = ({
  type,
  message,
}) => (
  <Form.Item className={`alert-box ${type}`}>
    {message}
  </Form.Item>
);

AlertBox.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

AlertBox.defaultProps = {
  type: ALERT_TYPE.NORMAL,
  message: '',
};
export default AlertBox;
