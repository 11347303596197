import { HTTP_CODE, ALERT_TYPE } from 'constants/index';

// eslint-disable-next-line import/prefer-default-export
export const getAlertType = (httpCode) => {
  let alertType = ALERT_TYPE.NORMAL;
  switch (httpCode) {
    case HTTP_CODE.SUCCESS:
      alertType = ALERT_TYPE.SUCCESS;
      break;
    case HTTP_CODE.UNAUTHORIZED:
      alertType = ALERT_TYPE.ERROR;
      break;
    case HTTP_CODE.SERVER_ERROR:
      alertType = ALERT_TYPE.ERROR;
      break;
    case HTTP_CODE.NOT_FOUND:
      alertType = ALERT_TYPE.ERROR;
      break;
    case HTTP_CODE.BAD_REQUESTED:
      alertType = ALERT_TYPE.ERROR;
      break;
    case HTTP_CODE.TOO_MANY_REQUESTS:
      // handle too many requests
      alertType = ALERT_TYPE.ERROR;
      break;
    default:
      break;
  }
  return alertType;
};
