import Login from 'components/pages/UnAuthorizedPages/Login/Login';

const login = ({ referer }) => (
  <>
    <Login referer={referer} />
  </>
);

login.title = 'login';
login.getInitialProps = async (context) => {
  const { req } = context;
  const referer = req?.cookies?.last_access;
  return { referer };
};
export default login;
