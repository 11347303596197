import {
  Button, Checkbox,
  Form,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { getAlertType } from 'common/alert';
import {
  checkResponseCode,
  getLangRoute,
  setResponse,
} from 'common/common';
import { detectedLang, getTransText } from 'common/translate';
import AlertBox from 'components/atoms/AlertBox/AlertBox';
import InputText from 'components/atoms/InputText/InputText';
import UnAuthorizedLayout from 'components/layouts/UnAuthorizedLayout/UnAuthorizedLayout';
import {
  ACCESS_TOKEN,
  API_ROUTES,
  AUTHORIZATION,
  FORCE_2FA_AUTHENTICATION,
  FORCE_UPDATE_PASSWORD,
  GET_SETTING,
  LANG_CODE,
  LAST_PAGE,
  PHPSESSID,
  REMEMBER_ME,
  ROUTES,
  SHARED_SESSION,
  USER_NAME,
} from 'constants/index';
import * as cookie from 'helpers/cookies';
import * as store from 'helpers/storage';
import { getStorage } from 'helpers/storage';
import isEmpty from 'lodash-es/isEmpty';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  useContext, useEffect,
  useRef,
  useState,
} from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import Axios from 'src/services/Axios/Axios';
import MainContexts from 'stores/context/MainContexts';

const Login = ({ referer }) => {
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState('');
  const [descriptionMessage, setDescriptionMessage] = useState('');
  const [headlineMessage, setHeadlineMessage] = useState('');
  const [resMessage, setResMessage] = useState(setResponse());
  const [clientCountry, setClientCountry] = useState('');
  const dataGetSettings = useContext(MainContexts);
  const mainImage = dataGetSettings?.initDataProvider?.getSettings?.main_image_path;
  const router = useRouter();
  const SITE_KEY = `${process.env.NEXT_PUBLIC_SITE_KEY}`;
  // eslint-disable-next-line no-unused-vars
  const SECRET_KEY = `${process.env.NEXT_PUBLIC_SECRET_KEY}`;
  const refRecaptcha = useRef();
  const [getLang, setLang] = useState('');
  const [isBot, setIsBot] = useState(false);
  const getSetting = getStorage(GET_SETTING);
  const previousPage = referer ?? cookie.getCookies(LAST_PAGE) ?? ROUTES.HOME;

  /**
   * add data into formdata login
   */
  const onSubmitHandler = async (values) => {
    let captChaResponse = {
      success: true,
    };
    // not use recaptcha in CN
    if (clientCountry !== LANG_CODE.CN) {
      await refRecaptcha.current.execute();
      // check user's behavior
      if (refRecaptcha.current.getResponse()) {
        captChaResponse = await Axios.post(`${API_ROUTES.GET_GG_CAPTCHA_RESPONSE}
      ?secret=${SECRET_KEY}&response=${refRecaptcha.current.getResponse()}`);
      }
    }

    if (captChaResponse.success) {
      store.setStorage(USER_NAME, values.username);
      try {
        setResMessage(setResponse());
        setLoading(true);
        await Axios.post(API_ROUTES.LOGIN, {
          username: values.username,
          password: values.password,
          remember: isEmpty(values.password) || !values.remember
            ? REMEMBER_ME.FALSE : REMEMBER_ME.TRUE,
          phpsessid: cookie.getCookies(PHPSESSID),
        }, (status, data) => {
          // console.log(data);
          // throw new Error();
          Axios.addHeader(AUTHORIZATION, `Bearer ${data.content.token}`);
          cookie.setCookie(ACCESS_TOKEN, data.content.token, {
            expires: isEmpty(values.password) || !values.remember
              ? REMEMBER_ME.UNCHECKED : REMEMBER_ME.CHECKED,
            domain: window.location.host,
          });
          // Create login session to share with site Legacy
          cookie.setCookie(SHARED_SESSION, data[SHARED_SESSION], {
            expires: isEmpty(values.password) || !values.remember
              ? REMEMBER_ME.UNCHECKED : REMEMBER_ME.CHECKED,
            domain: window.location.host,
          });
          cookie.setCookie('logged_2fa', true);
          cookie.setCookie('username', '');
          cookie.setCookie('remember', '');
          if (getSetting['2fa'] === 1) {
            cookie.setCookie('logged_2fa', false);
            cookie.setCookie('username', values.username);
            cookie.setCookie('remember', isEmpty(values.password) || !values.remember
              ? REMEMBER_ME.FALSE : REMEMBER_ME.TRUE);
            Router.push(ROUTES.TWOFA);
            cookie.setCookie(FORCE_2FA_AUTHENTICATION, getSetting['2fa'], {
              expires: 7,
              domain: window.location.host,
            });
          } else if (data.forceUpdatePassword) {
            Router.push(ROUTES.UPDATE_PASSWORD);
            cookie.setCookie(FORCE_UPDATE_PASSWORD, data.forceUpdatePassword, {
              expires: isEmpty(values.password) || !values.remember
                ? REMEMBER_ME.UNCHECKED : REMEMBER_ME.CHECKED,
              domain: window.location.host,
            });
          } else {
            window.location.href = getLangRoute(previousPage, router);
          }
        });
      } catch (err) {
        setLoading(false);
        setResMessage(setResponse(
          err.response?.status,
          err.response.data?.content,
        ));
        // Handle Error Here
      }
    } else {
      setIsBot(true);
      refRecaptcha.current.reset();
    }
  };
  useEffect(() => {
    if (isEmpty(getSetting)) {
      return null;
    }
    setHeadlineMessage(getSetting?.headline_message);
    setDescriptionMessage(getSetting?.description_message);
    setBackground(getSetting?.main_image_path);
    setClientCountry(getSetting?.country);
    return null;
  }, [getSetting]);

  useEffect(() => {
    setLang(router?.query?.lang);
  });
  return (
    <>
      <UnAuthorizedLayout>
        <>
          <div
            className="index-bg"
            style={{
              backgroundImage: `url(${mainImage ?? background})`,
            }}
          />
          <div className="fullscreen-layout body-login text-center">
            <div className="mt-6">
              <h3 className="title-well">{dataGetSettings?.initDataProvider?.getSettings?.headline_message ?? headlineMessage }</h3>
              <div className="sub-title">{dataGetSettings?.initDataProvider?.getSettings?.description_message ?? descriptionMessage}</div>
            </div>
            {checkResponseCode(resMessage.httpCode)
            && (
              <div className="err-box">
                <AlertBox
                  type={getAlertType(resMessage.httpCode)}
                  message={detectedLang(resMessage.messages)}
                />
              </div>
            )}
            <Form
              className="login-box box-shadow-form"
              layout="vertical"
              onFinish={onSubmitHandler}
              autoComplete="off"
            >
              <h3 className="login-title">{getTransText('login')}</h3>
              <Form.Item
                name="username"
                className="text-left letter-spacing-label"
                rules={[
                  {
                    required: true,
                    message: getTransText('usernameCannotEmpty'),
                  },
                ]}
              >
                <InputText
                  label={getTransText('username')}
                  placeholder={getTransText('username')}
                  name="username"
                  inputClassName="corp-input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                className="text-left mt-4 letter-spacing-label"
                rules={[
                  {
                    required: true,
                    message: getTransText('passWordCannotEmpty'),
                  },
                ]}
              >
                <InputText
                  label={getTransText('pw')}
                  placeholder="*********"
                  name="password"
                  type="password"
                  inputClassName="corp-input"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" style={{ float: 'left' }}>
                  <Checkbox className="checkbox-customize-all-page">
                    {' '}
                    <span className="remember-me-text">
                      {getTransText('rememberMe')}
                    </span>
                  </Checkbox>
                </Form.Item>
                <Form.Item style={{ float: ['es', 'cz'].includes(getLang) ? 'left' : 'right' }}>
                  <Text className="forgot-login-black">
                    <Link href={getLangRoute(ROUTES.FORGOT_PASSWORD)}>{getTransText('forgotPassword')}</Link>
                  </Text>
                </Form.Item>
              </Form.Item>
              <Recaptcha
                ref={refRecaptcha}
                style={{ visibility: 'hidden', height: 0 }}
                sitekey={SITE_KEY}
              />
              <Form.Item className="mt-4">
                <Button
                  className="button-login"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={isBot}
                >
                  {getTransText('login')}
                </Button>
              </Form.Item>
              <Form.Item className="skip-login-blue">
                <Text><Link href={getLangRoute(ROUTES.HOME)}>{getTransText('loginAsGuest')}</Link></Text>
              </Form.Item>
            </Form>
          </div>
        </>
      </UnAuthorizedLayout>
    </>
  );
};
Login.propTypes = {
  referer: PropTypes.string,
};

Login.defaultProps = {
  referer: undefined,
};

export default Login;
